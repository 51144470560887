import React, { useMemo } from "react";
import type { SelectProps } from "clutch/src/Select/Select.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { getAgentImage } from "@/game-val/utils.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function SelectAgent({
  selected,
  withIcons = false,
  ...restProps
}: Omit<SelectProps<string>, "options"> & { withIcons?: boolean }) {
  const {
    val: { meta: { agents: { list: agentList = [] } = {} } = {} },
  } = useSnapshot(readState);

  const options = useMemo(
    () => [
      {
        text: ["val:agents.all_agents", "All Agents"] as const,
        value: "all",
      },
      ...agentList
        .map(({ name, key }) => {
          return {
            image: withIcons ? getAgentImage(key) : undefined,
            text: [`val:agents.${key}`, name] as const,
            value: key,
          };
        })
        .sort(({ value: aValue }, { value: bValue }) =>
          aValue.localeCompare(bValue),
        ),
    ],
    [agentList],
  );
  return (
    <Select
      selected={selected || options[0].value}
      options={options}
      {...restProps}
    />
  );
}
