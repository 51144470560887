import React, { useMemo } from "react";
import { styled } from "goober";
import type {
  ReactZoomPanPinchHandlers,
  ReactZoomPanPinchProps,
} from "@pronestor/react-zoom-pan-pinch";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import { mobile } from "clutch/src/Style/style.mjs";
import type { DeepReadonly, ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import type { ValorantGuides } from "@/data-models/valorant-guides.mjs";
import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import keyInObject from "@/util/key-in-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type ValorantLineup = ElementOf<ValorantGuides["list"]>;

const MAP_OPTIONS: ReactZoomPanPinchProps = {
  limitToBounds: true,
  centerOnInit: true,
  centerZoomedOut: true,
  maxScale: 5,
  minScale: 0.5,
  wheel: {
    step: 0.9,
  },
};

type MapContentProps = {
  zoomIn: ReactZoomPanPinchHandlers["zoomIn"];
  zoomOut: ReactZoomPanPinchHandlers["zoomOut"];
  setTransform: ReactZoomPanPinchHandlers["setTransform"];
  otherLineups?: DeepReadonly<ValorantLineup[]> | ValorantLineup[];
} & ValorantLineup;

const MapPlaceholder = styled("svg")`
  display: block;
  width: auto;

  ${mobile} {
    height: auto;
    width: 100%;
  }
`;

const TransformOuter = styled("div")`
  aspect-ratio: 16 / 9;
  overflow: hidden;
  background-color: var(--shade8);

  .map-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`;
const MapFrame = styled("div")`
  position: relative;
  height: 0;
  margin: var(--sp-6) 0;
  padding-bottom: 100%;

  .map-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MapFrameInner = styled("div")`
  position: relative;

  img.layout-img,
  div.tips-frame {
    transform: rotate(var(--rotation-degree));
  }

  ${mobile} {
    height: auto;
    width: 100%;
  }

  .tips-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LineupIconWrapper = styled("div")`
  position: absolute;
  top: var(--lineup-y);
  left: var(--lineup-x);
  color: var(--shade0);
  transform: translate(-50%, -50%)
    rotate(calc(var(--rotation-degree, 0deg) * -1));
  border-radius: 50%;
  border: var(--sp-0_5) solid transparent;
  cursor: pointer;
  width: var(--sp-8);
  height: var(--sp-8);
  padding: var(--sp-1);

  &:hover,
  &.active {
    background: var(--agent-color-transparent);
    border-color: var(--agent-color);

    transform: translate(-50%, -50%)
      rotate(calc(var(--rotation-degree, 0deg) * -1)) scale(1.1);
    z-index: 2;
  }

  svg {
    color: var(--agent-color-light);
    opacity: 1;
    max-width: 100%;
    max-height: 100%;
  }
`;

type LineupIconProps = {
  positionX: number;
  positionY: number;
  ability: string;
  difficulty: string;
  className?: string;
  href?: string;
};

function LineupIcon({
  positionX,
  positionY,
  ability,
  difficulty,
  className,
  href,
}: LineupIconProps) {
  const linkProps = href ? { as: "a" as const, href } : {};
  return (
    <LineupIconWrapper
      style={{ "--lineup-x": `${positionX}%`, "--lineup-y": `${positionY}%` }}
      className={className}
      {...linkProps}
    >
      <AgentAbilityIcon ability={ability} />
      <DifficultyDot difficulty={difficulty} />
    </LineupIconWrapper>
  );
}

function MapContent({
  agent,
  map,
  side,
  otherLineups,
  ...lineup
}: MapContentProps) {
  const { val } = useSnapshot(readState);

  const selectedMap = useMemo(() => {
    const maps = val.meta.maps.list ?? [];
    return maps.find((m) => m.key === map);
  }, [val?.meta?.maps?.list, map]);

  const agentColorVars = useMemo(() => {
    const agentColor = keyInObject(AGENT_COLORS, agent)
      ? AGENT_COLORS[agent]
      : null;

    if (!agentColor) return undefined;

    return {
      "--agent-color": `hsl(${agentColor})`,
      "--agent-color-light": `hsl(${agentColor} / 0.9)`,
      "--agent-color-dark": `hsl(${agentColor} * 0.9)`,
      "--agent-color-transparent": `hsla(${agentColor}, 0.2)`,
      "--agent-color-transparent-light": `hsla(${agentColor}, 0.75)`,
    };
  }, [agent]);

  const sideOrientation =
    selectedMap?.images?.side_orientations &&
    keyInObject(selectedMap?.images?.side_orientations, side)
      ? selectedMap.images.side_orientations[side]
      : 0;

  if (!selectedMap) return null;

  return (
    <TransformOuter style={agentColorVars}>
      <TransformComponent wrapperClass="map-wrapper">
        <MapFrame>
          <MapFrameInner
            style={{
              "--rotation-degree":
                typeof sideOrientation === "number"
                  ? `${sideOrientation}deg`
                  : `0deg`,
            }}
          >
            <MapPlaceholder width="800" height="800" />
            <img
              src={selectedMap?.images?.layout}
              alt={selectedMap?.name}
              className="map-img layout-img"
            />
            {selectedMap?.images?.walls &&
            keyInObject(selectedMap.images.walls, side) ? (
              <img
                src={selectedMap.images.walls[side]}
                alt={selectedMap?.name}
                className="map-img"
              />
            ) : null}
            {selectedMap?.images?.labels &&
            keyInObject(selectedMap.images.labels, side) ? (
              <img
                src={selectedMap.images.labels[side]}
                className="map-img map-labels"
              />
            ) : null}
            <LineupIcon
              positionX={lineup.location.icon.x}
              positionY={lineup.location.icon.y}
              ability={lineup.icon}
              difficulty={lineup.difficulty}
              className="active"
            />
            {otherLineups && otherLineups.length > 0
              ? otherLineups.map((l) => {
                  const lineupParams = new URLSearchParams();
                  lineupParams.set("agent", l.agent);
                  lineupParams.set("map", l.map);
                  lineupParams.set("lineup", l.id);
                  return (
                    <LineupIcon
                      key={l.id}
                      positionX={l.location.icon.x}
                      positionY={l.location.icon.y}
                      ability={l.icon}
                      difficulty={l.difficulty}
                      href={`/valorant/lineups?${lineupParams}`}
                    />
                  );
                })
              : null}
          </MapFrameInner>
        </MapFrame>
      </TransformComponent>
    </TransformOuter>
  );
}

type LineupMapProps = {
  otherLineups?: DeepReadonly<ValorantLineup[]> | ValorantLineup[];
} & ValorantLineup;

export default function LineupMap({ otherLineups, ...lineup }: LineupMapProps) {
  return (
    <TransformWrapper {...MAP_OPTIONS}>
      {({ zoomIn, zoomOut, setTransform }) => (
        <MapContent
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          setTransform={setTransform}
          otherLineups={otherLineups}
          {...lineup}
        />
      )}
    </TransformWrapper>
  );
}
