import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import LineupMap from "@/game-val/components/LineupMap.jsx";
import { getGuideThumbnail } from "@/game-val/guides-utils.mjs";
import { getAgentImage } from "@/game-val/utils.mjs";
import { getLineupTags, useLineupMapper } from "@/game-val/utils/lineups.jsx";
import useAbilityNames from "@/game-val/utils/use-ability-names.mjs";
import BlitzPlay from "@/inline-assets/button-play-icon.svg";
import ShareButton from "@/shared/ShareButton.jsx";
import type { VideoEmbedProps } from "@/shared/VideoEmbed.jsx";
import VideoEmbed from "@/shared/VideoEmbed.jsx";
import type { LineupCardProps } from "@/shared-fps/LineupCards.jsx";
import LineupCards from "@/shared-fps/LineupCards.jsx";
import LineupModal from "@/shared-fps/LineupModal.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import titleCase from "@/util/title-case.mjs";
import useMediaQuery from "@/util/use-media-query.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const VideoContainer = styled("div")`
  position: relative;
  width: 100%;
  max-width: 100%;

  .share-button {
    position: absolute;
    top: var(--sp-2);
    right: var(--sp-2);
    background-color: var(--shade10);
    padding: var(--sp-2) var(--sp-4);
    border-radius: var(--br);
    z-index: 1;
  }
`;

const PlayButton = styled("button")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 1;

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  svg {
    width: var(--sp-16);
    height: var(--sp-16);
    transition: var(--transition);
  }
`;

const ViewAll = styled("a")`
  font-weight: 625;
  font-size: var(--sp-3_5);
  color: var(--shade2);
  padding: var(--sp-1) var(--sp-2);
`;

interface LineupModalProps {
  id: string;
}

interface MoreLineupsProps {
  lineups: LineupCardProps[];
  lineupType: string;
  compact?: boolean;
  vertical?: boolean;
  href?: string;
}

function MoreLineups({
  lineups,
  lineupType,
  compact,
  vertical,
  href,
}: MoreLineupsProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex between">
        <h2 className="type-large-title--bold">
          {t("common:moreLineups", "More {{lineupType}} Lineups", {
            lineupType: titleCase(lineupType),
          })}
        </h2>
        {href ? (
          <ViewAll href={href}>{t("common:viewAll", "View All")}</ViewAll>
        ) : null}
      </div>
      <LineupCards lineups={lineups} compact={compact} vertical={vertical} />
    </div>
  );
}

interface LineupVideoProps {
  videoSrc: VideoEmbedProps["src"];
  videoPoster?: string;
}

function LineupVideo({ videoSrc, videoPoster }: LineupVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setPlaying] = useState(false);

  const handlePlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const handlePause = useCallback(() => {
    setPlaying(false);
  }, []);

  const playVideo = useCallback(() => {
    videoRef.current?.play();
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;
    const videoElement = videoRef.current;
    videoElement.addEventListener("playing", handlePlay);
    videoElement.addEventListener("pause", handlePause);

    return () => {
      videoElement.removeEventListener("playing", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, [videoRef, handlePlay, handlePause]);

  return (
    <VideoContainer>
      <ShareButton
        className="share-button type-callout--semi shade0"
        withQuery
      />
      {!isPlaying && (
        <PlayButton onClick={playVideo}>
          <BlitzPlay />
        </PlayButton>
      )}
      <VideoEmbed
        className="w-full"
        videoPoster={videoPoster}
        src={videoSrc}
        controls
        isMuted
        videoRef={videoRef}
        autoPlay
        onClick={(e) => e.preventDefault()}
      />
    </VideoContainer>
  );
}

function ValLineupModal(props: LineupModalProps) {
  const abilityNames = useAbilityNames();
  const state = useSnapshot(readState);
  const lineupMapper = useLineupMapper();
  const { currentPath } = useRoute();
  const isMobile = useMediaQuery(mobile);

  const {
    val: { relatedGuides },
  } = state;
  const { tip, agentTips, mapTips } = relatedGuides?.[props.id] ?? {};

  if (!tip || !tip.id) return null;

  const videoSrc: VideoEmbedProps["src"] = tip?.ytvideo
    ? {
        yt: tip.ytvideo,
      }
    : {
        mp4: tip.video,
      };

  const { title, description } = tip;
  const iconSrc = getAgentImage(tip.agent);
  const tags = getLineupTags({
    ...tip,
    abilityName: abilityNames[tip.icon],
  });

  const relatedMapTips = mapTips.map(lineupMapper);
  const relatedMapMoreParams = new URLSearchParams();
  relatedMapMoreParams.set("map", tip.map);

  const relatedAgentTips = agentTips.map(lineupMapper);
  const relatedAgentMoreParams = new URLSearchParams();
  relatedAgentMoreParams.set("agent", tip.agent);
  relatedAgentMoreParams.set("map", tip.map);

  const videoPoster =
    getGuideThumbnail(tip.video) ??
    getAgentImage(tip.agent, "full-size-concepts");

  return (
    <LineupModal
      title={title}
      description={description}
      iconSrc={iconSrc}
      tags={tags}
      VideoComponent={
        <LineupVideo videoSrc={videoSrc} videoPoster={videoPoster} />
      }
      MapComponent={<LineupMap {...tip} otherLineups={agentTips} />}
      SidebarComponent={
        relatedAgentTips.length > 0 ? (
          <MoreLineups
            lineups={relatedAgentTips}
            lineupType={titleCase(tip.agent)}
            href={`${currentPath}?${relatedAgentMoreParams}`}
            vertical
            compact
          />
        ) : null
      }
      UserActions={
        relatedMapTips.length > 0 ? (
          <>
            <div className="flex-divider" />
            <MoreLineups
              lineups={relatedMapTips}
              lineupType={titleCase(tip.map)}
              href={`${currentPath}?${relatedMapMoreParams}`}
              vertical={!!isMobile}
              compact
            />
          </>
        ) : null
      }
      stickyContent
    />
  );
}

export default React.memo(ValLineupModal);
