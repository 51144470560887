import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "clutch/src/Tabs/index.js";

import type { Sides } from "@/game-val/guides-utils.mjs";
import {
  containerCss,
  tabContainerCss,
  tabLabelCss,
} from "@/shared/toggle-tabs.style.mjs";

interface SideToggleProps {
  side: string;
  setSide: (side: string) => void;
}

export default function SideToggle({ side, setSide }: SideToggleProps) {
  const { t } = useTranslation();
  const sideOptions = useMemo<{ name: Sides; content?: ReactNode }[]>(() => {
    return [
      {
        name: "attacking",
        content: (
          <div className="pad-0-3 h-full">
            <span className="toggle-description type-callout--semi">
              {t("common:attacking", "Attacking")}
            </span>
          </div>
        ),
      },
      {
        name: "defending",
        content: (
          <div className="pad-0-3 h-full">
            <span className="toggle-description type-callout--semi">
              {t("common:defending", "Defending")}
            </span>
          </div>
        ),
      },
    ];
  }, [t]);

  const activeTab = useMemo(() => {
    return sideOptions.findIndex((v) => v.name === side);
  }, [side, sideOptions]);
  return (
    <Tabs<Sides>
      tabOptions={sideOptions}
      onChange={(idx) => setSide(sideOptions[idx].name)}
      activeTab={activeTab}
      classes={{
        containerClass: containerCss(),
        tabContainerClass: tabContainerCss,
        tabLabelClass: tabLabelCss(),
      }}
    />
  );
}
