import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function useAbilityNames() {
  const {
    val: {
      meta: {
        agents: { list: agentList = [] },
      },
    },
  } = useSnapshot(readState);

  return useMemo(
    () =>
      agentList
        .flatMap((v) => v.abilities)
        .reduce<Record<string, string>>((acc, v) => {
          acc[v.key] = v.name;
          return acc;
        }, {}),
    [agentList],
  );
}
