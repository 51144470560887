import React, { useCallback } from "react";
import { styled } from "goober";
import type { TagProps } from "clutch/src/Tag/Tag.jsx";
import type { ElementOf } from "ts-essentials";

import { appURLs } from "@/app/constants.mjs";
import type { ValorantGuides } from "@/data-models/valorant-guides.mjs";
import AbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import { getGuideThumbnail } from "@/game-val/guides-utils.mjs";
import { getAgentImage, getMapImage } from "@/game-val/utils.mjs";
import useAbilityNames from "@/game-val/utils/use-ability-names.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import titleCase from "@/util/title-case.mjs";

type ValorantGuide = ElementOf<ValorantGuides["list"]>;

const VID_THUMB_BASE = `${appURLs.CDN}/blitz/val/vidThumbs/`;

export function getVideoThumbnail(videoPath: string): string | null {
  const [filePath] = videoPath.split("/").slice(-1);
  if (!filePath) return null;
  const lastDotIdx = filePath.lastIndexOf(".");
  if (lastDotIdx === -1) return null;
  const fileName = filePath.slice(0, lastDotIdx);
  return `${VID_THUMB_BASE}/${fileName}.jpg`;
}

export function getLineupTags(
  lineup: ValorantGuide & { abilityName: string },
): TagProps[] {
  return [
    {
      color: "var(--shade1)",
      text: lineup.abilityName,
      iconLeft: <AbilityIcon ability={lineup.icon} />,
      size: "small",
      as: "a",
      href: `/valorant/lineups?agent=${lineup.agent}&ability=${lineup.icon}`,
    },
    {
      color: "var(--shade1)",
      text: titleCase(lineup.map),
      size: "small",
      as: "a",
      href: `/valorant/lineups?map=${lineup.map}`,
    },
    {
      color: "var(--shade1)",
      text: titleCase(lineup.difficulty),
      iconLeft: <DifficultyDot difficulty={lineup.difficulty} />,
      size: "small",
      as: "a",
      href: `/valorant/lineups?difficulty=${lineup.difficulty}`,
    },
  ];
}

const Icon = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

export function useLineupMapper() {
  const abilityNames = useAbilityNames();
  const { currentPath, searchParams } = useRoute();
  const nextParams = new URLSearchParams(searchParams);
  return useCallback(
    (lineup: ValorantGuide) => {
      nextParams.set("lineup", lineup.id);
      return {
        id: lineup.id,
        title: lineup.title,
        href: `${currentPath}?${nextParams.toString()}`,
        imgSrc: getGuideThumbnail(lineup.video),
        icon: <Icon src={getAgentImage(lineup.agent)} />,
        tags: getLineupTags({
          ...lineup,
          abilityName: abilityNames[lineup.icon],
        }),
        fallbackImg: getMapImage(lineup.map),
      };
    },
    [abilityNames, currentPath, searchParams, nextParams],
  );
}
